import AppHelper from './app-helper';

export default class Pixels {
  static async triggerPixel(trackid) {
    const trackingData = AppHelper.getCommonTrackingData();
    if (trackingData.utm_id === 'zp') {
      const trackingURL = new URL('https://zeropark.classicspinz.com');

      const params = {
        cid: trackingData.utmContent,
        utm_source: trackingData.utmSource,
        utm_medium: trackingData.utmMedium,
        utm_campaign: trackingData.utmCampaign,
        utm_term: trackingData.keyword,
        track_id: trackid,
        gclid: trackingData.gclid,
      };

      Object.keys(params)
        .forEach((key) => trackingURL.searchParams.append(key, params[key]));

      try {
        const response = await fetch(trackingURL.toString(), {
          method: 'GET',
          mode: 'no-cors',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();
      } catch (error) {
        console.error('Fetch failed:', error);
      }
    }

    return null;
  }
}
