import axios from 'axios';
import AppHelper from './app-helper';

export default class TrackingVisit {
  spun;

  brandData;

  trackingData;

  pageData;

  pageBackground;

  pageColor;

  textAboveLogo;

  textAboveLogoColor;

  pageLogo;

  mobilePageLogo;

  pageTitle;

  pageTitleColor;

  pageSubtitle;

  pageSubtitleColor;

  pageButtonText;

  pageButtonTextColor;

  pageButtonColor;

  disclaimerText;

  disclaimerTextColor;

  disclaimerBackgroundColor;

  pageFooterLogo;

  geo;

  vertical;

  subvertical;

  networkName;

  pageAb;

  landingPage;

  pageLogoText;

  footerIcons;

  privacyPolicyUrl;

  firstVisit= window.sessionStorage.getItem('firstVisit') || null;

  async getPageData() {
    try {
      this.landingPage = AppHelper.getParameterByName('lpg') || window.location.hostname;
      this.trackingData = ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, (c) =>
        // eslint-disable-next-line no-bitwise, no-mixed-operators, implicit-arrow-linebreak
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
      // eslint-disable-next-line prefer-template
      this.trackingData += 'tt';
      const response = await axios.get(`https://${AppHelper.getHostNameAndCutIt()}/page-data.json`);
      this.pageData = response.data !== undefined ? response.data : null;
      this.pageBackground = this.pageData.pageBackground || this.pageBackground;
      this.pageColor = this.pageData.pageColor;
      this.textAboveLogo = this.pageData.textAboveLogo;
      this.textAboveLogoColor = this.pageData.textAboveLogoColor;
      this.pageLogo = this.pageData.pageLogo;
      this.mobilePageLogo = this.pageData.mobilePageLogo;
      this.pageLogoText = this.pageData.pageLogoText;
      this.pageTitle = this.pageData.pageTitle || this.pageTitle;
      this.pageTitleColor = this.pageData.pageTitleColor;
      this.pageSubtitle = this.pageData.pageSubtitle;
      this.pageSubtitleColor = this.pageData.pageSubtitleColor;
      this.pageButtonText = this.pageData.pageButtonText;
      this.pageButtonTextColor = this.pageData.pageButtonTextColor;
      this.pageButtonColor = this.pageData.pageButtonColor;
      this.disclaimerText = this.pageData.disclaimerText;
      this.disclaimerTextColor = this.pageData.disclaimerTextColor;
      this.disclaimerBackgroundColor = this.pageData.disclaimerBackgroundColor;
      this.pageFooterLogo = this.pageData.pageFooterLogo;
      this.pageWheelCenterImage = this.pageData.pageWheelCenterImage;
      this.privacyPolicyUrl = this.pageData.privacyPolicyUrl;
      this.networkName = this.pageData.networkName;
      this.geo = this.pageData.geo;
      this.vertical = this.pageData.vertical;
      this.subvertical = this.pageData.subvertical;
      this.pageAb = this.pageData.pageAb;
      this.footerIcons = this.pageData.footerIcons;
      if (!window.sessionStorage.getItem('firstVisit')) {
        window.sessionStorage.setItem('firstVisit', true);
        this.saveVisit();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async saveVisit() {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const commonData = AppHelper.getCommonTrackingData(this.networkName, this.geo, this.vertical, this.subvertical, this.pageAb);
      const data = {
        ...commonData,
        eventType: 'visit',
        trackid: this.trackingData || '',
      };
      data.gclid = data.gclid || data.loki || false;
      await axios.post(`${process.env.VUE_APP_CLICK_API}/api/v1/user/save`, JSON.stringify(data), config);
    } catch (error) {
      console.log(error);
    }
  }
}
