<template>
  <div class="app-body" id="app"
       v-bind:style="{ 'background-size': 'cover', 'background-image': trackingVisit?.pageBackground ? 'url(' + trackingVisit?.pageBackground + ')' : '' }">
    <div v-if="!trackingVisit?.pageData" class="lds-ellipsis"><img src="@/assets/loader.gif" alt="">
      Get ready to spin!
    </div>
    <div v-if="trackingVisit?.pageData" class="container">
      <div class="main-body">
        <div class="body-wrapper">
          <div class="left-column">
            <div class="text-above-logo"
                 v-bind:style="{ 'color': trackingVisit?.textAboveLogoColor ? trackingVisit?.textAboveLogoColor : '' }">
              {{ trackingVisit?.textAboveLogo || 'Try your Luck Today!' }}
            </div>
            <div class="logo">
              <img :src="trackingVisit?.pageLogo" alt="Logo" class="logo-large"
                   v-if="trackingVisit?.pageLogo"/>
              <img :src="trackingVisit?.mobilePageLogo" alt="Logo" class="logo-small"
                   v-if="trackingVisit?.mobilePageLogo"/>
              <div class="logo-div" v-if="!trackingVisit?.pageLogo && trackingVisit?.pageLogoText">
                <div class="logo-div--inner"><span alt="Logo"
                                                   class="logo-text">{{
                    trackingVisit?.pageLogoText
                  }}</span>
                </div>
              </div>
              <div v-if="!trackingVisit?.pageLogoText && !trackingVisit?.pageLogo">
                <img class="logo-large" :src="require('@/assets/main-logo.png')" alt=""/>
              </div>
              <div v-if="!trackingVisit?.pageLogoText && !trackingVisit?.mobilePageLogo">
                <img class="logo-small" :src="require('@/assets/mobile-logo.png')" alt=""/>
              </div>
            </div>
            <div class="main-content">
              <h1
                v-bind:style="{ 'color': trackingVisit?.pageTitleColor ? trackingVisit?.pageTitleColor : '' }">
                {{
                  trackingVisit?.pageTitle || "Your Winning Offer is Just a Spin Away!"
                }}</h1>
              <h2
                v-bind:style="{ 'color': trackingVisit?.pageSubtitleColor ? trackingVisit?.pageSubtitleColor : '' }">
                {{
                  trackingVisit?.pageSubtitle ||
                  "It's your lucky day! Spin our lucky wheel and unlock thrilling online casino offers."
                }}</h2>
            </div>
          </div>
          <div class="right-column">
            <Wheel v-if="trackingVisit?.pageData" :pageData="trackingVisit?.pageData"
                   @wheel-spun="onWheelSpun" @brand-data-loaded="onBrandDataLoaded"/>
          </div>
          <Modal :pageData="trackingVisit?.pageData" :trackid="trackingVisit?.trackingData"
                 :brandData="trackingVisit?.brandData" v-if="trackingVisit?.spun"/>
        </div>
      </div>
    </div>
    <div v-if="trackingVisit?.pageData" class="footer-wrapper"
         v-bind:style="{ 'backgroundColor': trackingVisit?.disclaimerBackgroundColor ? trackingVisit?.disclaimerBackgroundColor : '' }">
      <div class="container">
        <div class="footer-content">
          <!--- footer logo --->
          <div class="footer-logo"><img
            :src="trackingVisit?.pageFooterLogo || require('@/assets/footer-logo.png')"
            alt=""/></div>
          <!-- eslint-disable max-len - Disclaimer -->
          <p
            v-bind:style="{ 'color': trackingVisit?.disclaimerTextColor ? trackingVisit?.disclaimerTextColor : '' }">

              <span><b>Disclaimer:</b> Each offer has its own Ts&Cs to which they apply. By participating, you acknowledge that you have read, reviewed, and understood these terms. Spinning the wheel gives you a chance to win a FREE SPINS offer with ClassicSpinz, with each spin offering free spins subject to fulfilling the claim requirements.</span><br><br>
              <i>*To claim the free spins, you must first spin the wheel and enter your name and email address in a pop-up that will appear. By providing this information, you consent to receive marketing communications from us. After completing this step, you will be redirected to the casino landing page where you need to register to use the spins, and you will also receive an email with a link to the same page. Please note that additional Ts&Cs may apply, as each casino has its own set of Ts&Cs that you should carefully review before playing.</i><br><br>
              All opinions expressed in our content are solely our own and in no way those of the brand(s) featured therein. Any Ts&Cs specifically related to the advice within our content can be found directly in the content itself and on the operator's website. While we take great care to ensure all the information presented is up-to-date and accurate, we hereby disclaim all express guarantees and warrants featured. That is with regard to everything from marketability to applicability and relevance of the information. This website, along with its content, services, and any marketing communications we send are provided 'as is.' Your use and reliance on the information presented are entirely at your own risk."
            </p>
          <div class="footer-icons">
            <a v-for="(icon, index) in trackingVisit?.footerIcons" :key="index"
               :class="'footer-icon-link icon-' + slugify(icon.label)" :href="icon.value"
               target="_blank" rel="noindex nofollow noopener noreferrer">
              <img :class="'lazy icon-' + slugify(icon.label)"
                   :src="require('@/assets/icons/' + slugify(icon.label) + '.png')"
                   :alt="icon.label">
            </a>
          </div>
          <!--          <div><a class="privacy-policy-link" :href="trackingVisit?.privacyPolicyUrl">Política-->
          <!--            de Privacidade</a></div>-->
          <div class="footer-nav">
            <div class="nav-item"><a :href="/terms-and-condition/">Terms and Condition</a></div>
            <div class="nav-item"><a :href="/cookie-policy/">Cookie Policy</a></div>
          </div>
        </div>
      </div>
      <!-- Start of HubSpot Embed Code -->
      <!--      <script type="application/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/25231212.js?businessUnitId=15204561"></script>-->
      <!-- End of HubSpot Embed Code -->
      <!-- <script type="application/javascript" charset="utf-8" src="http://js.hubspot.com/forms/current.js"></script> -->
    </div>
  </div>
</template>

<script>
import Wheel from '@/components/Wheel.vue';
import Modal from '@/components/Modal.vue';
import TrackingVisit from './tracking-visit';

export default {
  name: 'App',
  components: {
    Wheel,
    Modal,
  },
  data() {
    return {
      trackingVisit: null,
    };
  },
  created() {
    this.trackingVisit = new TrackingVisit();
    this.trackingVisit.getPageData();
  },
  methods: {
    onWheelSpun(value) {
      this.trackingVisit.spun = value;
    },
    onBrandDataLoaded(brandData) {
      this.trackingVisit.brandData = brandData;
    },
    getSiteLocation() {
      return window.location.hostname;
    },
    slugify(text) {
      return text.toString().toLowerCase().trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
    },
    loadSvg(filename) {
      return fetch(`path/to/svgs/${filename}.svg`)
        .then((response) => response.text())
        .then((svgText) => {
          const parser = new DOMParser();
          const svg = parser.parseFromString(svgText, 'image/svg+xml').querySelector('svg');
          return svg;
        })
        .catch((error) => console.error(`Error loading SVG ${filename}: ${error}`));
    },
  },
};
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

* {
  font-family: "DM Sans";

}

.footer-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem 0;
  @media(max-width: 350px) {
    flex-direction: column;
  }
}

.nav-item {
  padding: 0 1rem;
  @media(max-width: 350px) {
    margin: auto;
  }
}

.nav-item a {
  color: #fff;
  text-decoration: none;
  text-align: center;
  align-items: center;
  font-size: 15px;
}

.nav-item a:hover {
  color: #F6B933;
}

.text-above-logo {
  font-family: "DM Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 2rem;
  color: #fff;
  text-align: center;
  @media(min-width: 1024px) {
    text-align: left !important;
  }
}

.logo-div--inner {
  text-align: left;
}

.logo-text {
  color: #FFB900;
  font-family: "Fugaz One";
  font-size: 100px;
  font-style: normal;
  font-weight: 400;
  line-height: 100px;
  @media(max-width: 1024px) {
    text-align: center;
    font-family: "Fugaz One";
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 15px;
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
  text-align: center;
  color: white;
}

.footer-icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;

  a {
    margin: .75rem;
  }

  svg {
    fill: #fff;
    display: block;
  }

  img {
    display: block;
  }
}

.plus-18-21 {
  width: 25px;

  &:not(:first-child) {
    margin-left: .75rem;
  }
}

.gamble-aware-icon, .icon-take-time-to-think {
  width: 150px;
}

/* Serbia Icons */
.icon-18-plus-serbia {
  width: 30px;
}

.icon-serbia-problem-gambling {
  width: 50px;
}

.icon-drajzerova-logo-second {
  width: 50px;
}

.icon-drajzerova-logo-first {
  width: 100px;
}

.icon-serbia-ministry-of-health {
  width: 100px;
}

/* End of Serbia Icons */
.gamcare-footer-icon {
  width: 100px;
}

.gambling-therapy-icon {
  width: 100px;
}

.icon-gordon-moody {
  width: 100px;
}

.icon-evalu-jeu {
  width: 100px;
}

.icon-ecogra {
  width: 110px;
}

.icon-ncpg {
  width: 100px;
}

.icon-800-gambler {
  width: 100px;
}

.icon-nj-responsible-gambling {
  width: 60px;
}

.icon-stopspillet {
  width: 100px;
}

.icon-ludomani {
  width: 100px;
}

.gamstop-icon {
  width: 100px;
}

.icon-gamcare {
  width: 100px;
}

.icon-rofus {
  width: 100px;
}

.icon-igc {
  width: 130px;
}

.icon-onjn-romania {
  width: 65px;
}

.icon-gamblers-anonymous {
  width: 110px;
}

.icon-michigan-gaming-control-board {
  width: 70px;
}

.icon-srij {
  width: 60px;
}

.icon-sicad {
  width: 130px;
}

.icon-adg-problem-gambling {
  width: 230px;
}

.icon-spillemyndigheden {
  width: 130px;
}

.icon-viradoojogobr {
  width: 300px;
}

.icon-jogadoresanonimosbr {
  width: 60px;
}

.icon-loketkansspelnl {
  width: 200px;
}

.icon-agognl {
  width: 100px;
}

.icon-24x7handsnl {
  width: 150px;
}

.icon-cruksnl {
  width: 100px;
}

.icon-juegosysorteos-mx {
  width: 170px;
}

.icon-segob-mx {
  width: 140px;
}

.icon-gambling-care {
  width: 200px;
}

.icon-gambling-therapy {
  width: 100px;
}

.icon-extern {
  width: 100px;
}

.icon-18-plus-chile {
  width: 140px;
}

.icon-18-plus {
  width: 30px;
}

.icon-21-plus {
  width: 30px;
}

.app-body {
  height: 100vh;
}

.footer-wrapper {
  @media(min-width: 1880px) {
    width: 100%;
  }
}

.footer-logo {
  margin: auto;
  text-align: center;
  max-width: 150px;
}

.privacy-policy-link {
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  text-decoration: none;

  a:visited {
    color: #fff;
  }
}

//.button--spin {
//  display: flex;
//  align-items: center;
//  &:not(.spun):hover {
//    background-color: #C4335E;
//    color: #ffffff;
//    box-shadow: 0px 4px 6px 2px rgba(206, 159, 58, .3);
//    transform: scale(1.075);
//    .rotate-arrow  {
//      transform: rotate(-360deg);
//    }
//  }
//  &.spun {
//    cursor: not-allowed;
//    opacity: 0.5;
//  }
//}
.rotate-arrow {
  font-weight: 700;
  font-size: 1.5em;
  margin-right: .5rem;
  transform: rotate(45deg);
  pointer-events: none;
  transition: 0.3s ease-in-out all
}

.logo-large {
  display: block;
  @media(max-width: 1024px) {
    display: none;
  }
}

.logo-small {
  display: none;
  @media(max-width: 1024px) {
    display: block;
    width: 308px;
  }
}

.main-body {
  padding: 1rem 0;
  display: flex;
  align-items: center;
}

.body-wrapper {
  display: flex;
  width: 100%;
}

.footer-wrapper {
  background-color: #29235D;
  padding: 1rem 0;
}

.footer-content {
  color: #B2B2B2;
  font-size: 12px;
  font-family: 'Roboto';
  font-weight: 400;
}

.right-column {
  flex: 1;
  margin-left: 4rem;
}

.button-desk {
  margin-top: 2rem;
}

.left-column {
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.main-content {
  h1 {
    font-family: "DM Sans";
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: #fff;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, .3);
    margin: 4rem 0 3rem;
  }

  h2 {
    margin: 0;
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, .3);
  }
}

@media (min-width: 1024px) {
  .button-mobile {
    display: none;
  }
}

@media (max-width: 1024px) {
  .logo-div--inner {
    text-align: center;
  }
}

@media (max-width: 1023px) {
  .main-body {
    display: block;
    padding-bottom: 2rem;
    background-size: cover;
    background-position: top;
  }
  .app-body {
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
    @media (max-width: 500px) {
      background: url('./assets/spin-mobile-bg.png');
    }
  }
  .body-wrapper {
    display: block;
    padding: 0;
  }
  .wheel {
    height: unset;
    width: unset;
  }
  .logo {
    margin-left: 0;

    img {
      margin: 0 auto;
    }
  }
  .button-desk {
    display: none;
  }
  .main-content {
    padding: 0 1rem;
    text-align: center;

    h1 {
      font-size: 2em;
      margin: 1rem 0;
    }

    h2 {
      font-size: 1.5em;
      padding: .5rem;
      margin: 0 -1rem;
    }
  }
  .footer-wrapper {
    margin-top: 0;
    padding: 1rem;
  }
  .footer-content {
    width: 100%;
  }
  .right-column {
    margin: 0;
    padding: 0 1rem;
  }
  .button-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  .logo-div {
    margin: auto;
  }
  .footer-icons {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .main-content {
    h1 {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 1rem 0;
    }

    h2 {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: unset;
    }
  }
  .text-above-logo {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  .body-wrapper {
    display: flex;
    flex-direction: column;
  }
  .left-column {
    order: 1;
  }
  .right-column {
    order: 3;
  }
  .button-mobile {
    order: 2;
  }
  .button-mobile {
    margin: 1rem 0 .5rem;
  }

  .button--spin {
    font-size: 1.25em;
    padding: .5rem 2rem;
  }
  .app-body {
    width: unset;
  }
  .wheel {
    transform: scale(1.4);
    top: 5.5rem;
    @media (max-width: 350px) {
      top: 4.5rem;
    }
  }
  .right-column {
    padding: 0;
  }
  .main-body {
    padding-bottom: 0;
  }
}
</style>
