export default class AppHelper {
  static getParameterByName(paramName, url = window.location.href) {
    const name = paramName.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return false;
    if (!results[2]) return false;
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  // eslint-disable-next-line
  static getCommonTrackingData(networkName = 'Agency PPC', geo, vertical, subvertical, pageAb) {
    return {
      networkName: networkName || 'Agency PPC',
      domain: window.location.hostname || '',
      page: '/',
      pageId: '0',
      utm_id: this.getParameterByName('utm_id') || 'false',
      utmSource: this.getParameterByName('utm_source') || 'false',
      utmMedium: this.getParameterByName('utm_medium') || 'false',
      utmTerm: this.getParameterByName('utm_term') || 'false',
      utmContent: this.getParameterByName('utm_content') || 'false',
      utmCampaign: this.getParameterByName('utm_campaign') || 'false',
      gclid: this.getParameterByName('gclid') || false,
      msclkid: this.getParameterByName('msclkid') || 'false',
      loki: this.getParameterByName('loki') || false,
      keyword: this.getParameterByName('keyword') || 'false',
      matchtype: this.getParameterByName('matchtype') || 'false',
      device: this.getParameterByName('device') || 'false',
      userAgent: navigator.userAgent || '',
      adpos: this.getParameterByName('adpos') || 'false',
      geo: geo || '',
      vertical: vertical || '',
      subvertical: subvertical || '',
      ab: pageAb || 'false',
      activeAgg: '',
      blockDetected: false,
      wasRightClick: 0,
    };
  }

  static getHostNameAndCutIt = () => {
    const host = window.location.hostname;
    const parts = host.split('.');
    if (parts.length > 2) {
      return `${parts[1]}-spin-the-wheel.pages.dev`;
    }
    return `${parts[0]}-spin-the-wheel.pages.dev`;
  }
}
