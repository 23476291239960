import axios from 'axios';
import AppHelper from './app-helper';

export default class TrackingClick {
  show;

  pageData;

  submitting;

  modalBackgroundColor;

  modalTitle;

  modalTitleColor;

  enableBrandOffer;

  modalSubtitle;

  modalSubtitleColor;

  modalBrandTextColor;

  modalWinnerMessage;

  modalWinnerMessageColor;

  modalInputTextColor;

  modalInputFirstField;

  modalInputSecondField;

  modalInputThirdField;

  modalFormButtonText;

  modalFormButtonTextColor;

  modalFormButtonBackgroundColor;

  modalCheckboxTextColor;

  modalCheckboxText;

  modalLogo;

  modalCheckboxTextSecond;

  geo;

  vertical;

  subvertical;

  networkName;

  pageAb;

  hidden;

  landingPage;

  redirectMessage = 'Success! Check your inbox and confirm your email address to receive special offers.';

  showRedirectModal;

  async saveClick(brandData, trackid, form, formType) {
    try {
      if (brandData === null || brandData.brandData === undefined) return;
      if (brandData.brandData.length === 0) return;
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const commonData = AppHelper.getCommonTrackingData(this.networkName, this.geo, this.vertical, this.subvertical, this.pageAb);
      const data = {
        ...commonData,
        eventType: 'click',
        trackid: trackid || '',
        brandId: brandData.brandData[0].brand_id || '0',
        clickSource: `wheel-${formType}`,
        brandPos: '1',
        brandName: brandData.brandData[0].brand_name || '',
        brandTableId: brandData.tableId || '0',
        brandTableName: brandData.tableName || '',
        brandOfferName: brandData.brandData[0].brand_offer_name || '',
        ftdValue: brandData.brandData[0].ftd_value || '0',
      };
      data.gclid = data.gclid || data.loki || false;
      await axios.post(`${process.env.VUE_APP_CLICK_API}/api/v1/user/save`, JSON.stringify(data), config);
      TrackingClick.saveToGtm(data);
    } catch (error) {
      console.log(error);
    }
  }

  static saveToGtm(data) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'formSubmittedData',
      formSubmittedData: data,
    });
  }

  async getModalData() {
    this.landingPage = AppHelper.getParameterByName('lpg') || window.location.hostname;
    const response = await axios.get(`https://${AppHelper.getHostNameAndCutIt()}/page-data.json`);
    this.pageData = response.data !== undefined ? response.data : null;
    this.modalBackgroundColor = this.pageData.modalBackgroundColor;
    this.modalTitle = this.pageData.modalTitle;
    this.modalTitleColor = this.pageData.modalTitleColor;
    this.enableBrandOffer = this.pageData.enableBrandOffer;
    this.modalSubtitle = this.pageData.modalSubtitle;
    this.modalSubtitleColor = this.pageData.modalSubtitleColor;
    this.modalBrandTextColor = this.pageData.modalBrandTextColor;
    this.modalWinnerMessage = this.pageData.modalWinnerMessage;
    this.modalWinnerMessageColor = this.pageData.modalWinnerMessageColor;
    this.modalInputTextColor = this.pageData.modalInputTextColor;
    this.modalInputFirstField = this.pageData.modalInputFirstField;
    this.modalInputSecondField = this.pageData.modalInputSecondField;
    this.modalInputThirdField = this.pageData.modalInputThirdField;
    this.modalFormButtonText = this.pageData.modalFormButtonText;
    this.modalFormButtonTextColor = this.pageData.modalFormButtonTextColor;
    this.modalFormButtonBackgroundColor = this.pageData.modalFormButtonBackgroundColor;
    this.modalCheckboxTextColor = this.pageData.modalCheckboxTextColor;
    this.modalCheckboxText = this.pageData.modalCheckboxText;
    this.modalLogo = this.pageData.modalLogo;
    this.modalCheckboxTextSecond = this.pageData.modalCheckboxTextSecond;
    this.networkName = this.pageData.networkName;
    this.geo = this.pageData.geo;
    this.vertical = this.pageData.vertical;
    this.subvertical = this.pageData.subvertical;
    this.pageAb = this.pageData.pageAb;
    this.privatePolicyUrl = this.pageData.privatePolicyUrl;
    this.show = true;
  }
}
